.new-feature {
  position: absolute;
  top: -0.5rem;
  left: -0.8rem;
  width: 35px;
  height: 16px;
  font-size: 10px !important;
  padding: 1px !important;
  transform: rotate(332deg);
}
