@import 'styles/kep-imports';

$primaryLightColor: mix(white, $primary, 10) !default;
$primaryColor: $primary !default;
$primaryDarkColor: mix(black, $primary, 20) !default;
$primaryDarkerColor: mix(white, $primary, 30) !default;
$primaryTextColor: #ffffff !default;
$primaryAccentBg: #cce5ff !default;

$highlightBg: $primaryColor !default;
$highlightTextColor: $primaryTextColor !default;
$highlightFocusBg: darken($primaryColor, 8%) !default;

@import '../_variables';
@import './_fonts';
@import '../../../../theme-base/_components';
@import '../_extensions';
