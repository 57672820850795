apx-chart {
  svg {
    background-color: inherit !important;
  }

  .apexcharts-tooltip {
    .card {
      margin-bottom: 0 !important;
    }
  }
}

.apexcharts-tooltip-title {
  background-color: white !important;
  border-bottom-color: #f5f8fa !important;
}

.dark-layout .apexcharts-tooltip-title {
  background-color: #0f1014 !important;
  border-bottom-color: #15171c !important;
}

.apexcharts-tooltip {
  border: none !important;
  background-color: white !important;
  box-shadow: 0 0 10px rgba(34, 41, 47, 0.1) !important;
}

.dark-layout .apexcharts-tooltip {
  background-color: #0f1014 !important;
  box-shadow: none;
}
