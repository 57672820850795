.card.card-info {
  border: none;
  opacity: 1;
  height: 150px;
  box-shadow: var(--inherit-bg-box-shadow) !important;
  background: inherit !important;

  &:hover {
    transform: scale(1.05);
  }

  .card-header .card-title {
    color: #fff;
  }

  .info-img {
    position: absolute;
    height: 120px;
    width: 120px;
    bottom: 0.5rem;
    right: 0.5rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
