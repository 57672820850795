
html {
  overflow-y: auto;
}

th {
  font-weight: 400 !important;
  text-transform: initial !important;
}

code {
  padding: 0;
}

body {
  padding-right: 0 !important
}

pre {
  all: revert;
  margin: 1em 0;
  padding: 1rem;
  border-radius: .375em;
  border: 1px solid #ededed !important;
  background-color: #fafafa !important;
  font-size: 87.5%;
  font-family: 'Inconsolata', serif;
  overflow: auto;
}

.dark-layout pre {
  background-color: inherit !important;
  color: white;
}

@media (min-width: 1440px) {
  .container-xxl {
    max-width: 1440px;
  }
}

#main-menu-navigation > li[core-menu-horizontal-item],
li[core-menu-horizontal-collapsible],
.main-menu.expanded li[core-menu-vertical-item],
.menu-expanded li[core-menu-vertical-item] {
  &.active > a > keen-icon > span, &.active > div > keen-icon > span {
    color: white !important;
  }
}

.main-menu {
  transition: 1s ease;
}

.main-menu:not(.expanded) keen-icon > span {
  margin-right: 1rem;
  transition: 1s ease-in;
}

@include media-breakpoint-down(sm) {
  .nav-tabs .nav-item {
    width: 100%;
  }
}

:root {
  --inherit-bg-box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

:root .dark-layout {
  --inherit-bg-box-shadow: 0 2px 12px 0 rgba(255, 255, 255, 0.05);
}

a.nav-link:hover {
  background-color: rgba($primary, 0.05);
  border-radius: 5px;
  transition: 0.2s ease-in;
}

.list-group-item:hover {
  background-color: rgba($primary, 0.05) !important;
}

.fc-list-empty {
  background-color: inherit !important;
}

a, li {
  transition: none !important;
}

.dropdown-item:hover, .dropdown-item:focus {
  border-radius: 4px !important;
}

.nav .dropdown-toggle:not(.active)::after {
  margin-right: 0.5rem !important;
}

.kep-active {
  border-radius: 4px !important;
  background: var(--primary);
  color: white !important;

  &:hover {
    background: var(--primary);
    box-shadow: 0 0 5px var(--primary);
  }

  &.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important;
  }
}

.dropdown-item {
  padding: 0.75rem !important;
}

.call-center-selection-range {
  .p-dropdown {
    height: 36px !important;
  }
}
