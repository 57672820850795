.beautiful-table {
  transition: all 0.2s ease;
  opacity: 0.9;
  border: none;
  // box-shadow: var(--inherit-bg-box-shadow) !important;
  border-radius: 5px;

  th,
  td {
    border-top: none;
    padding-top: 1.15rem;
    padding-bottom: 1.15rem;
  }

  thead tr:hover {
    transform: none !important;
  }
}
