.badge {
  --bs-badge-padding-x: 0.9em;
  --bs-badge-padding-y: 0.65em;
  --bs-badge-font-size: 0.81em;
  --bs-badge-font-weight: 500;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.25rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.badge-pill {
  --bs-badge-padding-x: 0.8em;
  --bs-badge-padding-y: 0.55em;
  --bs-badge-border-radius: 50rem;
}

/*
.badge {
  font-weight: 500 !important;
  line-height: 1.4 !important;
  font-size: 12px !important;
  transition: all 0.3s ease-out !important;
  -webkit-transition: all 0.3s ease-out !important;
  display: inline-block !important;
  padding: 4.6px 8px !important;
  border-radius: 6px !important;
}

.badge-pill {
  display: inline-block !important;
  // padding: 0.3rem 0.5rem !important;
  padding: 0.2rem 0.6rem !important;
  border-radius: 10rem !important;
  font-size: 85% !important;
  // font-weight: 600 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: baseline !important;
}
*/
.dark-layout .badge.badge-light-dark {
  color: #e9e7e7 !important;
}
