
.form-control, .ng-select .ng-select-container {
  // border-color: #eaedef !important;
  // border: none !important;
  transition: 0s;
  // box-shadow: 0 0 5px #eaedef;

  @mixin dark-layout() {
    box-shadow: none;
    border: 1px solid #5C5F70 !important;
    background-color: $theme-dark-thirddegree;
  }
}

.ng-dropdown-panel {

  @mixin dark-layout() {
    background-color: #161E31;
    border: 1px solid #1f222c !important;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {

  @mixin dark-layout() {
    background-color: #161E31;
    color: #fff;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {

  @mixin dark-layout() {
    background-color: #161E31;
  }
}
