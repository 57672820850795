
.dark-layout ::-webkit-scrollbar {
  width: 15px;
  background-color: $theme-dark-secondary;
}

::-webkit-scrollbar {
  width: 15px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px $primary;
  background-color: #D62929;
}

::-webkit-scrollbar-thumb {
  border: 5px solid transparent;
  border-radius: 100px;
  background-color: $primary;
  background-clip: content-box;
}
