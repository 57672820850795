.p-inplace {
  .p-inplace-display {
    padding: $inplacePadding;
    border-radius: $borderRadius;
    transition: $formElementTransition;

    &:not(.p-disabled):hover {
      background: $inplaceHoverBg;
      color: $inplaceTextHoverColor;
    }

    &:focus {
      @include focused();
    }
  }
}

