.ng2-flatpickr-input {
  background-color: inherit !important;

  @mixin dark-layout() {
    background-color: #1d2028 !important;
  }
}

.flatpickr-calendar, span.flatpickr-weekday, .flatpickr-month, .flatpickr-monthDropdown-month, .flatpickr-monthDropdown-months {
  @mixin dark-layout {
    background-color: #1d2028 !important;
  }
}

.flatpickr-calendar {
  display: none !important;

  &.open {
    display: block !important;
  }
}
