
#main-menu-navigation > li.active > a, #main-menu-navigation > li.active > div {
  background: $primary;
  box-shadow: 0 4px 4px 0 #0065FF1F;
  color: #fff;
  border-radius: 4px;
}

app-navbar {
  border-bottom: 1px solid #f1eded;

  @mixin dark-layout {
    border-bottom: 1px solid #15171c;
  }
}
