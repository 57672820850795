@use "sass:meta";
@import 'scss/core.scss';
@import 'styles/vuexy-extended';
@import 'styles/primeng-sass/themes/lara/lara-light/indigo/theme';
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import "assets/fonts/inter/fonts.css";
@import "styles/kep-imports";

a, div, input, title {
  font-family: "Inter", sans-serif;
}

apx-chart * {
  font-family: "Inter", sans-serif !important;
  color: $body-color !important;

  @mixin dark-layout() {
    color: white !important;
  }
}

.ag-header-row {
  background: #D0E1FD !important;
  @mixin dark-layout() {
    background: #181D2A !important;
  }
}

.ag-header {
  background: #D0E1FD !important;
  @mixin dark-layout() {
    background: #181D2A !important;
  }
}

.ag-pinned-right-header {
  background: #D0E1FD !important;
  @mixin dark-layout() {
    background: #181D2A !important;
  }
}

.ag-body-viewport {
  background: #ffffff;
  @mixin dark-layout() {
    background: #272E40;
  }
}

.ag-header-cell-text {
  color: rgba(71, 84, 103, 1);
  @mixin dark-layout() {
    color: #D0D2D6 !important;
  }
}

.dark-layout {
  @include meta.load-css('styles/primeng-sass/themes/lara/lara-dark/indigo/theme');

  .ag-row {
    background-color: #343D55FF !important;
    color: #FFFFFF !important;
  }
}

.dark-layout .p-inputtext {
  background-color: $theme-dark-input-bg;
}

.dark-layout .p-dropdown {
  background: #343D55;
}

.apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
  width: 230px !important;
  margin-right: 3px;
}

@media screen and (max-width: 1600px) {
  .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
    width: 250px !important;
  }
}

@media screen and (max-width: 1400px) {
  .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
    width: 230px !important;
  }
}

@media screen and (max-width: 1300px) {
  .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
    width: 180px !important;
  }
  .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series .apexcharts-legend-text {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 1100px) {
  .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
    width: 120px !important;
  }
}

.jvectormap-region {
  cursor: pointer;
}

.jvectormap-zoomin,
.jvectormap-zoomout {
  background-color: $primary !important;
  top: 0;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 90%;
}

.jvectormap-zoomin {
  left: auto;
  right: 28px;
}

.jvectormap-zoomout {
  left: auto;
  right: 0;
}

// notification, toastr style
.toast-container {
  &.toast-top-right {
    top: 70px;
    right: 27px;
  }

  .ngx-toastr {
    border-radius: 7px;
    border: 2px solid;
    max-width: 400px;

    .toast-title {
      font-size: 15px !important;
      font-weight: 600 !important;
      line-height: 16px !important;
      margin-bottom: 10px !important;
    }

    .toast-message {
      font-size: 13px !important;
      font-weight: 500 !important;
      line-height: 14px !important;
      width: 100% !important;
    }

    .toast-close-button {
      margin-top: -10px !important;
    }

    &.toast-success {
      background-color: #e5fff0 !important;
      //border-color: #1cb361 !important;
      box-shadow: none !important;
      border: none !important;
      background-image: url(./assets/toastr-icons/check.png) !important;

      .toast-title,
      .toast-message,
      .toast-close-button {
        color: #1cb361 !important;
      }
    }

    &.toast-error {
      background-color: #ffe9e8 !important;
      //border-color: #f44336 !important;
      box-shadow: none !important;
      border: none !important;
      background-image: url(./assets/toastr-icons/error.png) !important;

      .toast-title,
      .toast-message,
      .toast-close-button {
        color: #f44336 !important;
      }
    }

    &.toast-info {
      background-color: #eef8ff !important;
      box-shadow: none !important;
      border: none !important;
      background-image: url(./assets/toastr-icons/info.png) !important;

      .toast-title,
      .toast-message,
      .toast-close-button {
        color: #2196f3 !important;
      }
    }

    &.toast-warning {
      background-color: #fffbf4 !important;
      //border-color: rgba(253, 176, 33, 0.3) !important;
      box-shadow: none !important;
      border: none !important;
      background-image: url(./assets/toastr-icons/warning.png) !important;

      .toast-title,
      .toast-message,
      .toast-close-button {
        color: #fdb021 !important;
      }
    }
  }
}

.cdk-overlay-container {
  z-index: 1051 !important;
}

.table-header-text-center .ag-header-cell-label {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.table-header-text-end .ag-header-cell-label {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: end;
}

.p-treetable .p-treetable-tbody > tr > td {
  text-align: center;
  border: 1px solid #dee2e6;
  padding: 0;
  //white-space: nowrap; /* Prevent wrapping */
  //overflow: hidden;
  //text-overflow: ellipsis;
}

.p-treetable .p-treetable-thead > tr > th {
  text-align: center;
  padding: 1px 1px;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: #ffffff;
  background: #4d4ae8;
  transition: box-shadow 0.2s;
  //overflow: hidden;
  //text-overflow: ellipsis;
}

.p-treetable-scrollable-header-box {
  padding: 0 !important;
}

.p-treetable-scrollable-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.p-treetable-scrollable-body {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid #dee2e6;
  border-left: 0 solid #dee2e6;
  border-right: 0 solid #dee2e6;
}

.modal-dialog-centered.modal-dialog-scrollable {
  margin: 0 auto !important;
}

ag-grid-angular .ag-root-wrapper {
  border-radius: 8px;
}

.ag-cell {
  align-content: center;
}

.ag-theme-alpine .ag-row {
  background-color: #fafdff;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
