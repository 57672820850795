@import '../bootstrap-extended/include';
// Bootstrap includes
@import '../components/include'; // Components includes

.dark-layout {
  @at-root body#{&} {
    color: $theme-dark-body-color;
    background-color: $theme-dark-body-bg;
  }
  body {
    color: $theme-dark-body-color;
    background-color: $theme-dark-body-bg;
  }

  // universal elements
  //heading tags
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $theme-dark-headings-color;
  }

  // border color
  .border,
  .border-top,
  .border-right,
  .border-bottom,
  .border-left {
    border-color: $theme-dark-border-color !important;
  }

  // other tags
  a {
    &:hover {
      color: $primary;
    }
  }

  label {
    color: $theme-dark-label-color;
  }

  .btn span {
    color: inherit;
  }

  hr {
    border-color: $theme-dark-border-color;
  }

  pre {
    background-color: $theme-dark-body-bg !important;
    border: 0;

    code {
      background-color: inherit;
      text-shadow: none;

      // .operator,
      .url {
        background-color: $theme-dark-body-bg;
      }
    }
  }

  code {
    background-color: $theme-dark-body-bg;
  }

  kbd {
    background-color: $theme-dark-body-bg;
  }

  // for dark text
  .text-dark {
    color: white !important;
  }

  .text-muted {
    color: $theme-dark-text-muted-color !important;
  }

  .text-body {
    color: $theme-dark-body-color !important;
  }

  .text-body-heading {
    color: $theme-dark-headings-color;
  }

  .section-label {
    color: $theme-dark-text-muted-color;
  }

  // shadow
  .shadow {
    box-shadow: $theme-dark-box-shadow !important;
  }

  // blockquote footer
  .blockquote-footer {
    color: $theme-dark-text-muted-color;
  }

  // modules navbar and header navbar shadow(generic navbar)
  .header-navbar-shadow {
    background: linear-gradient(180deg, rgba(22, 29, 49, 0.9) 44%, rgba(22, 29, 49, 0.43) 73%, rgba(22, 29, 49, 0));
  }

  // modules header navbar dark style

  .horizontal-layout {
    .header-navbar {
      background-color: $theme-dark-card-bg !important;
    }

    &.navbar-sticky,
    &.navbar-static {
      .header-navbar {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }

  .navbar-sticky {
    .header-navbar {
      &.navbar-shadow {
        // box-shadow: 0 4px 24px 0 rgba($black, 0.75);
        box-shadow: none;
      }
    }
  }

  .header-navbar {
    background-color: $theme-dark-card-bg;

    .navbar-container {
      .nav {
        .nav-item {
          .nav-link {
            color: $theme-dark-headings-color;
            background-color: transparent;

            i,
            svg {
              color: $theme-dark-headings-color;
            }

            &.bookmark-star {
              i,
              svg {
                color: $white !important;
              }
            }
          }

          &.nav-search {
            .search-input {
              &.open {
                background-color: $theme-dark-card-bg;

                .input {
                  border-color: $theme-dark-border-color;
                }

                .input,
                .input::placeholder,
                .search-input-close {
                  color: $theme-dark-body-color;
                }
              }
            }
          }

          .bookmark-input {
            background-color: $theme-dark-card-bg;

            .form-control {
              background-color: $theme-dark-card-bg;
            }
          }

          .search-list {
            background-color: $theme-dark-card-bg;

            li {
              a {
                color: $theme-dark-body-color;
              }
            }

            .auto-suggestion.current_item {
              background-color: $theme-dark-body-bg;
            }
          }
        }

        li i.ficon,
        li svg.ficon {
          color: $theme-dark-body-color;
        }

        .dropdown-cart,
        .dropdown-notification {
          .dropdown-menu.dropdown-menu-media {
            overflow: hidden;

            .media {
              border-color: $theme-dark-border-color;

              .media-meta {
                color: $theme-dark-body-color;
              }

              &:hover {
                background-color: $theme-dark-body-bg;
              }
            }

            .dropdown-menu-header {
              border-bottom: 1px solid $theme-dark-border-color;
            }

            .dropdown-menu-footer {
              background-color: $theme-dark-card-bg;
              border-top: 1px solid $theme-dark-border-color;

              .dropdown-item {
                border-color: $theme-dark-border-color;

                &:hover {
                  background-color: $theme-dark-card-bg;
                }
              }
            }
          }
        }

        .dropdown-cart {
          &.empty-cart {
            &:before {
              background-color: $theme-dark-body-bg;
            }
          }

          li {
            color: $white;
          }

          .cart-item {
            color: $theme-dark-body-color;
          }

          .dropdown-header {
            span {
              color: $white;
            }
          }

          .media {
            img {
              background-color: $theme-dark-body-bg;
            }
          }

          .media-heading .cart-item-by {
            color: $theme-dark-text-muted-color;
          }
        }

        .dropdown-notification {
          .media-body .media-heading {
            color: $theme-dark-headings-color;
          }

          .notification-text {
            color: $theme-dark-text-muted-color;
          }
        }
      }
    }

    &.navbar-static-top {
      background-color: transparent !important;
    }

    &[class*='bg-'] {
      .navbar-nav {
        .nav-item {
          .nav-link {
            background-color: inherit;
          }
        }
      }
    }
  }

  //Text color for Blank page with transparent card
  .blank-page {
    .card.bg-transparent {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $theme-dark-card-bg;
      }

      p {
        color: $theme-dark-body-bg;
      }
    }
  }

  // modules menu
  .main-menu {
    background-color: $theme-dark-card-bg;

    .shadow-bottom {
      background: linear-gradient(
                      180deg,
                      $theme-dark-card-bg 44%,
                      rgba($theme-dark-card-bg, 0.51) 73%,
                      rgba($theme-dark-card-bg, 0)
      );
    }

    &:not(.expanded) {
      .navigation {
        .sidebar-group-active {
          > a {
            background-color: $theme-dark-body-bg;
          }
        }
      }
    }

    &.menu-dark {
      .navigation > li.open:not(.menu-item-closing) > a {
        background-color: $theme-dark-body-bg;
      }

      .navigation > li.sidebar-group-active > a {
        background-color: $theme-dark-body-bg;
      }
    }

    .collapse-toggle-icon {
      color: $primary !important;
    }
  }

  // modules menu content
  .main-menu-content {
    .navigation-main {
      background-color: $theme-dark-card-bg;

      .navigation-header {
        color: $theme-dark-text-muted-color;
      }

      li {
        a {
          color: $theme-dark-headings-color !important;
        }

        ul {
          .open,
          .sidebar-group-active {
            > a {
              background-color: $theme-dark-body-bg;
            }
          }
        }
      }

      .nav-item {
        i,
        svg {
          color: $theme-dark-headings-color;
        }

        &.open {
          > a {
            background-color: $theme-dark-body-bg !important;
          }
        }

        a:after {
          color: $theme-dark-headings-color;
        }

        .menu-content {
          background-color: $theme-dark-card-bg;

          .active {
            .menu-item {
              color: $white;
            }
          }
        }
      }

      .active {
        .menu-title,
        i,
        svg {
          color: $white;
        }
      }

      .sidebar-group-active {
        .menu-content {
          background-color: $theme-dark-card-bg;

          .active {
            z-index: 1;

            a {
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  // menu collapsed active item color
  .menu-collapsed {
    .main-menu:not(.expanded) {
      .navigation-main {
        li.active {
          > a {
            background: $theme-dark-body-bg !important;
          }
        }
      }
    }
  }

  /********* COMPONENTS *********/
  // alerts
  .alert {
    .alert-heading,
    p {
      color: inherit;
    }

    .close {
      text-shadow: none;
    }

    &.alert-dark {
      .alert-heading,
      .alert-body,
      p {
        color: $gray-600;
      }

      &.alert-dismissible .close {
        color: $gray-600 !important;
      }
    }
  }

  // breadcrumbs

  // Header breadcrumb
  .content-header-left {
    .breadcrumbs-top {
      .content-header-title {
        color: $theme-dark-headings-color;
        border-color: $theme-dark-border-color;
      }
    }
  }

  // Breadcrumb dark style
  .breadcrumb {
    .breadcrumb-item {
      color: $theme-dark-body-color;

      + .breadcrumb-item::before {
        color: $theme-dark-body-color;
      }
    }

    &:not([class*='breadcrumb-']) {
      .breadcrumb-item + .breadcrumb-item {
        &:before {
          background-image: url(str-replace(
                          str-replace($chevron-right, 'currentColor', $theme-dark-body-color),
                          '#',
                          '%23'
          ));
        }
      }
    }
  }

  // Breadcrumb with Chevron
  .breadcrumb-chevron {
    &.breadcrumb {
      .breadcrumb-item + .breadcrumb-item:before {
        background-image: url(str-replace(
                        str-replace($chevron-right, 'currentColor', $theme-dark-body-color),
                        '#',
                        '%23'
        ));
      }
    }
  }

  // collapse

  [class*='collapse-'] .card:not(:last-of-type) {
    border-color: $theme-dark-border-color;
  }

  // for shadow and bordered
  .collapse-border {
    .card {
      border-color: $theme-dark-border-color;
    }
  }

  // Collapse shadow
  .collapse-shadow {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.24);
  }

  // margin collapse
  .collapse-margin {
    .card {
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.24) !important;
    }
  }

  // if there is an anchor tag inside
  .collapse-icon {
    .card-header {
      a {
        color: $theme-dark-body-color;
      }
    }

    [data-toggle='collapse'] {
      &:after {
        background-image: url(str-replace(
                        str-replace($chevron-down, 'currentColor', $theme-dark-body-color),
                        '#',
                        '%23'
        ));
      }

      &:before {
        background-image: url(str-replace(
                        str-replace($chevron-up, 'currentColor', $theme-dark-body-color),
                        '#',
                        '%23'
        ));
      }
    }
  }

  // buttons
  .btn {
    &:not([class*='btn-']) {
      color: $theme-dark-body-color;
    }

    &.btn-dark,
    &.btn-outline-dark,
    &.btn-flat-dark {
      color: $gray-600;
    }

    &.btn-dark {
      background-color: $dark !important;
    }

    &.btn-outline-dark {
      border-color: $dark;
      color: $gray-600;

      &:hover:not(.disabled):not(:disabled) {
        color: $gray-600;
      }
    }

    &.btn-flat-dark {
      &:active,
      &:focus {
        background: $dark;
      }
    }
  }

  .btn-group-toggle {
    label[class*='btn-outline-'] {
      color: $primary !important;
    }
  }

  // dropdown
  .dropdown-menu {
    background-color: $theme-dark-card-bg;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.24);

    .dropdown-header {
      color: $theme-dark-headings-color;
    }

    .dropdown-item {
      color: $theme-dark-body-color;

      &:hover,
      &:focus {
        background: $dropdown-link-hover-bg;
        color: $dropdown-link-hover-color;
      }

      &:active,
      &.active {
        color: $dropdown-link-active-color;
        background-color: $dropdown-link-active-bg;
      }

      &.disabled,
      &:disabled {
        color: $theme-dark-text-muted-color;
      }
    }
  }

  .dropdown-divider {
    border-color: $theme-dark-border-color;
  }

  // modal
  .modal {
    .modal-header,
    .modal-header[class*='bg-'] {
      // why removed !important? Not working if added bg-COLOR class with modal-header
      // background-color: $theme-dark-card-bg !important;
      background-color: $theme-dark-body-bg;
    }

    // close button
    .modal-header .close {
      text-shadow: none;
      background-color: $theme-dark-card-bg !important;
      color: $theme-dark-body-color;
      box-shadow: 0 3px 8px 0 rgba(11, 10, 25, 0.49) !important;
    }

    .modal-content,
    .modal-body,
    .modal-footer {
      background-color: $theme-dark-card-bg;
      border-color: $theme-dark-border-color;
    }
  }

  //pagination
  .pagination {
    &:not([class*='pagination-']) {
      .page-item {
        &.active {
          background-color: $theme-dark-pagination-bg;

          .page-link {
            background-color: $primary;

            &:hover {
              color: $white;
            }
          }
        }

        .page-link {
          background-color: $theme-dark-pagination-bg;
          color: $theme-dark-body-color;

          &:hover {
            color: $primary;
          }
        }

        &.prev-item,
        &.next-item {
          .page-link:hover {
            color: $white;
          }
        }
      }
    }

    &[class*='pagination-'] {
      .page-item:not(.active) {
        .page-link {
          background-color: $theme-dark-pagination-bg;
        }

        &:not(:hover) {
          .page-link {
            color: $white;
          }
        }
      }

      .page-item {
        &:not(.active) {
          .page-link:hover {
            background-color: $theme-dark-pagination-bg;
          }
        }

        &.active {
          background-color: $theme-dark-pagination-bg;
        }
      }
    }

    .page-item {
      &.prev-item,
      &.previous,
      &.prev {
        .page-link:before {
          background-image: url(str-replace(
                          str-replace($chevron-left, 'currentColor', $theme-dark-body-color),
                          '#',
                          '%23'
          ));
        }

        &.disabled {
          .page-link {
            &:before {
              background-image: url(str-replace(
                              str-replace($chevron-left, 'currentColor', $theme-dark-text-muted-color),
                              '#',
                              '%23'
              ));
            }
          }
        }
      }

      &.next-item,
      &.next {
        .page-link::after {
          background-image: url(str-replace(
                          str-replace($chevron-right, 'currentColor', $theme-dark-body-color),
                          '#',
                          '%23'
          ));
        }

        &.disabled {
          .page-link {
            &:after {
              background-image: url(str-replace(
                              str-replace($chevron-right, 'currentColor', $theme-dark-text-muted-color),
                              '#',
                              '%23'
              ));
            }
          }
        }
      }

      &.disabled {
        .page-link {
          color: $theme-dark-text-muted-color;
        }
      }
    }
  }

  // nav tabs
  .nav-tabs,
  .nav-pills,
  .nav-tabs.nav-justified {
    .nav-item {
      .nav-link {
        color: $theme-dark-headings-color;

        &.active {
          background-color: $theme-dark-card-bg;
          color: $primary;
        }

        &.disabled {
          color: $theme-dark-text-muted-color;
        }
      }
    }
  }

  // bordered nav
  .nav.wrap-border {
    border-color: $theme-dark-border-color;
  }

  // nav pills
  .nav-pills {
    .nav-item {
      .nav-link {
        &.active {
          color: $white;
          background-color: $primary;
        }
      }
    }
  }

  // media
  .media-bordered {
    .media:not(:first-child) {
      border-color: $theme-dark-border-color;
    }
  }

  // popover
  .popover {
    &[x-placement='top'] {
      .arrow {
        &:before {
          border-top-color: $theme-dark-border-color;
        }

        &:after {
          border-top-color: $theme-dark-card-bg;
        }
      }
    }

    &[x-placement='left'] {
      .arrow {
        &:before {
          border-left-color: $theme-dark-border-color;
        }

        &:after {
          border-left-color: $theme-dark-card-bg;
        }
      }
    }

    &[x-placement='right'] {
      .arrow {
        &:before {
          border-right-color: $theme-dark-border-color;
        }

        &:after {
          border-right-color: $theme-dark-card-bg;
        }
      }
    }

    .popover-header {
      color: $white;
    }

    .popover-body {
      background-color: $theme-dark-card-bg;
      color: $theme-dark-body-color;
      border-color: $theme-dark-border-color !important;
    }
  }

  // BS toast
  .toast {
    background-color: $theme-dark-card-bg !important;
    box-shadow: $theme-dark-box-shadow;
    color: $theme-dark-body-color;

    .toast-header {
      background-color: $theme-dark-card-bg !important;
      color: $theme-dark-body-color;

      .close {
        background-color: transparent !important;
        box-shadow: none !important;
        text-shadow: none;
      }
    }
  }

  // list group
  .list-group {
    .list-group-item {
      &:not([class*='list-group-item-']),
      &.list-group-item-action:not(.active):not(:active) {
        background-color: $theme-dark-card-bg;
        border-color: $theme-dark-border-color;

        &:not(.disabled) {
          color: $theme-dark-body-color;
        }
      }

      &.list-group-item-action {
        &:not(.active):not(:active) {
          &:hover,
          &:focus {
            background-color: $theme-dark-body-bg;
          }
        }

        &.active,
        &:active {
          background-color: $primary;
          color: $white;
        }
      }

      &.active {
        p,
        small {
          color: $white;
        }
      }

      &.disabled {
        color: $theme-dark-text-muted-color;
      }
    }

    &.list-group-circle .list-group-item:after {
      background-image: url(str-replace(str-replace($circle, 'currentColor', $theme-dark-body-color), '#', '%23'));
    }
  }

  // spinners
  .spinner-border {
    border-color: currentColor !important;
    border-right-color: transparent !important;
  }

  // avatar
  .avatar {
    background-color: $theme-dark-body-bg;

    [class*='avatar-status-'] {
      border-color: $theme-dark-card-bg;
    }
  }

  .avatar-group {
    .avatar {
      img,
      .avatar-content {
        box-shadow: 0 0 0 2px $theme-dark-card-bg, inset 0 0 0 1px rgba($theme-dark-card-bg, 0.07);
      }
    }
  }

  // divider
  .divider {
    .divider-text {
      color: $theme-dark-body-color;

      &::before,
      &::after {
        border-color: $theme-dark-border-color;
      }
    }

    &.divider-dark {
      .divider-text {
        &::before,
        &::after {
          border-color: $theme-dark-border-color !important;
        }
      }
    }
  }

  // Timeline
  .timeline {
    .timeline-item {
      border-color: $theme-dark-border-color;

      .timeline-point:not(.timeline-point-indicator) {
        background-color: $theme-dark-card-bg;
      }

      .timeline-event {
        .timeline-event-time {
          color: $theme-dark-text-muted-color;
        }
      }

      &:last-of-type:after {
        background: linear-gradient($theme-dark-border-color, transparent);
      }
    }
  }

  // card
  .card {
    background-color: $theme-dark-card-bg !important;
    box-shadow: $theme-dark-box-shadow !important;

    .card-footer {
      border-color: $theme-dark-border-color;
    }

    &.overlay-img-card {
      .card-img-overlay {
        span,
        p,
        .card-body {
          color: $white;
        }
      }
    }
  }

  //--------------- Advance Cards ---------------
  //---------------------------------------------

  // Developer Meetup Card
  .card-developer-meetup {
    .meetup-header .meetup-day {
      border-right-color: $theme-dark-input-border-color;
    }
  }

  // Profile Card
  .card-profile {
    .profile-image-wrapper .profile-image {
      background-color: $theme-dark-body-bg;
    }
  }

  // Business Card
  .business-card {
    .business-items .business-item {
      border-color: $theme-dark-border-color;
    }
  }

  // App Design Card
  .card-app-design {
    .design-planning-wrapper .design-planning {
      background-color: $theme-dark-body-bg;
    }
  }

  //--------------- Statistics Card ---------------
  //------------------------------------------------

  .card-tiny-line-stats {
    .apexcharts-series-markers {
      .apexcharts-marker {
        stroke: $info;
      }

      &:last-child {
        .apexcharts-marker {
          fill: $theme-dark-card-bg;
        }
      }
    }
  }

  //-------------- Analytics Cards --------------
  //---------------------------------------------

  // Revenue Report Card
  .card-revenue-budget {
    .revenue-report-wrapper {
      border-right-color: $theme-dark-border-color;
    }

    .budget-wrapper {
      .apexcharts-series:not(:first-child) {
        path {
          stroke: $theme-dark-body-color;
        }
      }
    }
  }

  // Earnings Card
  .earnings-card {
    .apexcharts-canvas .apexcharts-pie {
      .apexcharts-datalabel-label {
        fill: $theme-dark-body-color !important;
      }

      .apexcharts-datalabel-value {
        fill: $theme-dark-headings-color;
      }
    }
  }

  // Company Table Card
  .card-company-table {
    .avatar {
      background-color: $theme-dark-body-bg;
    }
  }

  /********** FORMS **********/

  // Autofill style
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px $theme-dark-card-bg inset !important;
    -webkit-text-fill-color: $theme-dark-body-color !important;
  }

  // input
  input.form-control,
  textarea.form-control {
    background-color: $theme-dark-input-bg;
    color: $theme-dark-body-color;

    &:not(.is-valid):not(.is-invalid):not(:focus) {
      border-color: $theme-dark-input-border-color;
    }

    &::placeholder {
      color: $theme-dark-input-placeholder-color;
    }

    &:disabled,
    &[readonly='readonly'] {
      opacity: 0.5;
    }
  }

  // custom file input label background
  .custom-file-input {
    ~ .custom-file-label {
      background-color: $theme-dark-input-bg;
      color: $theme-dark-body-color;
    }

    &:disabled {
      ~ .custom-file-label {
        opacity: 0.5;
      }
    }
  }

  // text area counter
  .char-textarea {
    &.active {
      color: $theme-dark-body-color !important;
    }

    &.max-limit {
      color: $danger !important;
    }
  }

  // file uploader default
  .custom-file-label {
    &:after {
      background-color: $theme-dark-body-bg;
      color: $theme-dark-body-color;
    }
  }

  // custom input file border color
  .custom-file-label {
    border-color: $theme-dark-border-color;
  }

  // custom control like checkbox, radio and switch
  .custom-control-input {
    &:not(:checked) {
      ~ .custom-control-label:before {
        background-color: $theme-dark-card-bg;
      }
    }

    &:not(:checked):not(:indeterminate):not(:invalid):not(.is-invalid):not(:valid):not(.is-valid) {
      ~ .custom-control-label:before {
        border-color: $theme-dark-input-border-color;
      }
    }

    &:disabled {
      ~ .custom-control-label:before {
        background-color: $theme-dark-input-disabled-border-color;
        border-color: $theme-dark-input-disabled-border-color;
      }
    }
  }

  .custom-switch {
    .custom-control-input:not(:checked) {
      ~ .custom-control-label:before {
        background-color: $theme-dark-switch-bg;
      }
    }

    .custom-control-input:disabled {
      ~ .custom-control-label:before {
        background-color: $theme-dark-switch-bg-disabled;
      }
    }
  }

  // select
  select.form-control,
  .custom-select {
    background-color: $theme-dark-input-bg;
    color: $theme-dark-body-color;
    border-color: $theme-dark-border-color;

    option:checked {
      background-color: $theme-dark-card-bg;
    }

    &:disabled {
      opacity: 0.5;
    }

    &:focus {
      border-color: $primary;
    }
  }

  select.form-control {
    &:not([multiple='multiple']) {
      background-image: url(str-replace(
                      str-replace($chevron-down, 'currentColor', $theme-dark-body-color),
                      '#',
                      '%23'
      ));
    }
  }

  // Added for override
  .was-validated {
    .form-control:invalid,
    .form-control.is-invalid {
      border-color: $danger !important;
    }

    .form-control:valid,
    .form-control.is-valid {
      border-color: $success !important;
    }
  }

  // form wizard
  .wizard {
    .steps {
      ul {
        .disabled,
        .done {
          a {
            color: $theme-dark-body-color !important;

            &:hover {
              color: $theme-dark-body-color;
            }

            .step {
              background-color: $theme-dark-body-bg !important;
              color: $white !important;
            }
          }
        }
      }
    }
  }

  // input groups
  .input-group {
    .input-group-text {
      background-color: $theme-dark-input-bg;
      border-color: $theme-dark-border-color;
      color: $theme-dark-body-color;
    }

    &:focus-within {
      .form-control,
      .input-group-text {
        border-color: $primary;
        box-shadow: none;
      }
    }
  }

  // floating label
  .form-label-group {
    & > input:not(:focus):not(:placeholder-shown) ~ label,
    & > textarea:not(:focus):not(:placeholder-shown) ~ label {
      color: $theme-dark-body-color !important;
    }
  }

  .counter-value {
    color: white;
  }

  /********** TABLE **********/

  // table
  .table {
    // background-color: $theme-dark-table-bg;

    td,
    th {
      color: $theme-dark-body-color;
      border-color: $theme-dark-border-color !important;
    }

    .thead,
    tbody {
      tr:not([class*='table-']) {
        th,
        td {
          border-color: $theme-dark-border-color;
          color: $theme-dark-body-color;

          code {
            background-color: $theme-dark-table-row-bg;
          }
        }
      }
    }

    thead,
    tfoot {
      tr {
        th {
          border-color: $theme-dark-border-color;
          background-color: $theme-dark-table-header-bg;
          color: $theme-dark-headings-color;
        }
      }

      &.thead-dark th {
        background-color: $white;
        color: $dark !important;
      }
    }

    // if table not light/dark and thead not have dark class
    &:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
    &:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
      background-color: $theme-dark-table-header-bg;
    }

    // table-dark style
    &.table-dark {
      background-color: $white;

      &.table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba($dark, 0.05);

        td {
          background-color: inherit;
        }
      }

      td,
      th {
        border-color: #f8f9fa !important;
        color: $dark !important;
        background-color: $white;

        .text-white {
          color: $dark !important;
        }
      }
    }

    tbody {
      tr {
        // for contextual classes
        &[class*='table-'] {
          td,
          th {
            background-color: unset;
          }
        }

        &.table-dark {
          td,
          th {
            color: $white;
          }
        }

        &.table-active {
          td,
          th {
            color: $theme-dark-body-color;
          }
        }
      }
    }

    // bordered table
    &.table-bordered {
      border-color: $theme-dark-border-color;
    }

    // table hover row color
    &.table-hover {
      tbody {
        tr {
          &:hover {
            background-color: $theme-dark-table-hover-bg;
          }

          th,
          td {
            background-color: unset;
          }
        }
      }
    }

    // table striped
    &.table-striped {
      tbody {
        tr {
          &:nth-of-type(odd) {
            background-color: $theme-dark-table-striped-bg;
          }
        }
      }
    }
  }

  /********** PAGES **********/

  // coming soon
  .getting-started {
    .clockCard {
      p {
        color: $theme-dark-body-color !important;
      }
    }
  }

  // profile page
  #user-profile {
    .profile-header {
      .navbar {
        background-color: $theme-dark-card-bg;

        .navbar-toggler {
          color: $theme-dark-body-color;
          border-color: $theme-dark-border-color;
        }
      }
    }
  }

  // Search page
  .search-bar {
    .form-control {
      background-color: $theme-dark-card-bg;
    }
  }

  // Blog Edit Page
  .blog-edit-wrapper {
    .border {
      border-color: $theme-dark-border-color !important;
    }
  }

  // Knowledge Base
  .kb-search-content-info {
    .kb-search-content {
      .card-img-top {
        background-color: #3f4860;
      }
    }
  }

  // list group circle for kb
  .list-group-circle {
    .list-group-item:not([class*='list-group-item-']),
    .list-group-item-action {
      &:hover,
      &:focus,
      &:active {
        background-color: transparent !important;
      }
    }
  }

  /********** APPLICATIONS **********/

  // App common sidebar and content dark style
  .content-area-wrapper {
    border-color: $theme-dark-border-color !important;

    .sidebar {
      .sidebar-content {
        background-color: $theme-dark-card-bg !important;
      }
    }

    .app-fixed-search {
      background-color: $theme-dark-card-bg !important;
      border-color: $theme-dark-border-color !important;
    }

    .content-right {
      border-color: $theme-dark-border-color !important;
    }
  }

  .footer-fixed {
    .footer {
      background-color: $theme-dark-card-bg;
    }
  }

  /********** HORIZONTAL LAYOUT **********/
  .horizontal-layout {
    &.navbar-sticky .horizontal-menu-wrapper .navbar-horizontal.header-navbar.fixed-top {
      // box-shadow: 0 4px 24px 0 rgba($black, 0.8) !important;
      box-shadow: none !important;
    }

    .horizontal-menu-wrapper {
      background: linear-gradient(
                      to bottom,
                      rgba(37, 43, 71, 0.76) 44%,
                      rgba(56, 53, 53, 0.46) 73%,
                      rgba($white, 0) 100%
      ) !important;

      .header-navbar {
        background: $theme-dark-card-bg !important;

        &.navbar-shadow {
          box-shadow: 0px 2px 10px 0px #00329626;
        }

        &.navbar-horizontal {
          ul#main-menu-navigation > li:hover:not(.active) > div {
            background: $theme-dark-body-bg;
          }

          .active {
            .nav-link {
              &.dropdown-toggle::after {
                background-image: url(str-replace(str-replace($chevron-down, 'currentColor', $white), '#', '%23'));
              }
            }
          }

          &.navbar-dark {
            background: $theme-dark-card-bg !important;
          }

          .nav-link {
            &.dropdown-toggle::after {
              background-image: url(str-replace(
                              str-replace($chevron-down, 'currentColor', $theme-dark-body-color),
                              '#',
                              '%23'
              ));
            }
          }
        }
      }
    }

    .main-menu-content {
      .navbar-nav {
        .dropdown-submenu {
          &.show {
            background-color: $theme-dark-card-bg !important;
            color: $theme-dark-body-color;
          }
        }

        .dropdown-menu {
          a {
            &:hover {
              color: $theme-dark-headings-color !important;
            }
          }

          .disabled a {
            color: $theme-dark-text-muted-color;
          }

          .dropdown-item {
            color: $theme-dark-headings-color;
          }

          .dropdown-toggle {
            &::after {
              background-image: url(str-replace(
                              str-replace($chevron-right, 'currentColor', $theme-dark-body-color),
                              '#',
                              '%23'
              ));
            }

            &:hover,
            &:active {
              &::after {
                background-image: url(str-replace(str-replace($chevron-right, 'currentColor', $white), '#', '%23'));
              }
            }
          }
        }

        > li.active {
          > a {
            > i,
            > svg {
              color: $white !important;
            }
          }

          .dropdown-menu {
            li.active > a {
              background: $theme-dark-body-bg !important;
              color: $theme-dark-body-color;

              &:hover {
                color: $primary !important;
              }
            }
          }

          .dropdown-menu {
            li.open.active {
              > a {
                color: $theme-dark-body-color !important;
              }
            }
          }
        }
      }
    }
  }

}
